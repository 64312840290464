<template>
  <div class="inline-block items-center">
    <BaseTooltip v-if="name" :position="placement">
      <template #target>
        <slot />
      </template>
      <template #default>
        <div class="max-w-[30rem] break-words px-2 text-left">
          <div class="mb-0.5 font-bold normal-case text-white">{{ name }}</div>
          <div
            v-html="definition"
            :class="['whitespace-normal break-words font-semibold normal-case text-gray-300']"
          ></div>
        </div>
      </template>
    </BaseTooltip>
    <div v-if="!name"><slot /></div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const $store = useStore();

const props = defineProps({
  id: String,
  placement: String
});

const metricTooltips = computed(() => {
  return $store.getters.metricTooltips;
});
const definition = computed(() => {
  const index = metricTooltips.value?.findIndex(x => x.metric_key == props.id);
  return index >= 0 ? metricTooltips.value[index].definition : '';
});
const name = computed(() => {
  const index = metricTooltips.value?.findIndex(x => x.metric_key == props.id);
  return index >= 0 ? metricTooltips.value[index].name : '';
});
</script>
